import React, { useEffect, useState } from 'react';
import axios from 'axios';

const AUTOGEN_API = "https://yxhgluw0ig.execute-api.us-west-2.amazonaws.com/prod/general/hello";
const CUSTOM_API = "https://api.johnhubberts.com/general/hello";

const healthStates = {
  LOADING: 'loading',
  UNHEALTHY: 'unhealthy',
  HEALTHY: 'healthy'
};

const generatePrefetchFunction = (url, setter) => {
  return () => {
    async function inner() {
      try {
        await axios.get(url);
        setter(healthStates.HEALTHY);
      } catch (e) {
        setter(healthStates.UNHEALTHY);
        console.warn(e);
      }
    }

    inner();
  }
};

const SiteHealth = () => {
  const [autogenHealth, setAutogenHealth] = useState(healthStates.LOADING);
  const [customHealth, setCustomHealth] = useState(healthStates.LOADING);

  useEffect(generatePrefetchFunction(AUTOGEN_API, setAutogenHealth), []);
  useEffect(generatePrefetchFunction(CUSTOM_API, setCustomHealth), []);

  return (
    <div>
      <p>Autogenerated Endpoint Is: {autogenHealth}</p>
      <p>Custom Endpoint Is: {customHealth}</p>
    </div>
  )
};

export default SiteHealth